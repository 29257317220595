import levy_horni from './leaves/Levy horni.jpeg'
import pravy_dolni from './leaves/Pravy dolni.jpeg'
import jitrocel from './leaves/jitrocel kopinaty.jpeg'
import './App.css'
import Info from './Info'
import {Fab, Grid} from '@mui/material'
import React from 'react'
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import Announcement from './Announcement'
import {useNavigate} from 'react-router-dom'

// eslint-disable-next-line react/prop-types
const Row = ({left, right}) => (
    <>
        <Grid item  xs={4} sx={{ textAlign: 'right', fontWeight: 'bold' }}>
            {left}
        </Grid>
        <Grid item  xs={8} sx={{ textAlign: 'left', fontStyle: 'italic', textTransform: 'capitalize' }}>
            {right}
        </Grid>
    </>
)

const Homepage = () => {
    const navigate = useNavigate()

    return (
        <div className="App">
            <div className="section basic">
                <img className="floating" src={levy_horni} style={{ left: 0, top: 0 }} />
                <span className="kad">Katka & Alda</span>
                <Announcement />
                {/*<img className="floating" src={jetele} style={{ height: '20vh', bottom: '-10vh' }} />*/}
                <img className="floating" src={pravy_dolni} style={{ right: 0, bottom: 0 }} />
                <ExpandMoreSharpIcon sx={{ position: 'absolute', bottom: 0 }} fontSize="large" className="arrow" />
            </div>
            <div className="section additional">
                <div style={{ fontWeight: 'bold'}}>V sobotu 30. září 2023</div>
                <div>ve 13 hodin</div>
                <div>Lomnice u Tišnova - zámecký park</div>
                <img src={jitrocel} style={{ height: '5rem', marginBottom: '1rem'}}/>
                <q>Díra do světa se lépe zvládne, když jsme dva!</q>
                <ExpandMoreSharpIcon sx={{ position: 'absolute', bottom: 0 }} fontSize="large" className="arrow"/>
            </div>
            <div className="section harm">
                <div>
                    <div style={{ fontWeight: 'bold', fontSize: '1.5rem'}}>HARMONOGRAM</div>
                    <Grid container spacing={2} sx={{ padding: '2rem' }} alignItems="stretch" justify="space-between">
                        <Row left="od 12:30" right="příjezd svatebčanů na místo obřadu (zámecký park Lomnice)" />
                        <Row left="13:00" right="svatební obřad" />
                        <Row left="po obřadu" right="gratulace svědků, nejbližší rodiny a svatebčanů, kteří nepokračují na oslavy, focení skupinek, přesun na místo oslav v Lomničce" />
                        <Row left="15:00" right="přípitek, možnost gratulací, raut (Lomnička)" />
                        <Row left="16:00" right="krájení dortu" />
                        <Row left="16:30" right="první tanec" />
                        <Row left="dále" right="zábava, raut, hry (k dispozici bude např. crossbowl, finské kuželky aj.; improvizovaný dětský koutek)" />
                        <Row left="před setměním" right="prskavkové foto" />
                        <Row left="po 22:00" right="přesun zábavy do vnitřních prostor" />
                    </Grid>
                    <div style={{ textAlign: 'left', marginTop: '2rem'}}>
                        <div style={{ margin: '0.7rem 0'}}>Časy jsou pouze orientační. Vše záleží na situaci - nechceme nic honit na minutky. Spíš doporučujeme být v danou dobu na místě dění, ať o nic nepřijdete 😊</div>
                        <div style={{ margin: '0.7rem 0'}}>Po celou dobu oslav bude k dispozici odvoz po okolí a do penzionů.</div>
                        <div style={{ margin: '0.7rem 0'}}>Byli bychom moc rádi, aby pro vás svatební oslavy byly příležitostí se po delší době setkat s přáteli či rodinou, seznámit se s ostatními a společně strávit příjemné odpoledne/večer.</div>
                    </div>
                    <div style={{ marginTop: '3rem', marginBottom: '2rem'}}>Těšíme se na vás!</div>
                </div>
                <ExpandMoreSharpIcon sx={{ position: 'absolute', bottom: 0 }} fontSize="large" className="arrow"/>
            </div>
            <Grid container spacing={2} sx={{ padding: '2rem' }} alignItems="stretch" justify="space-between">
                <Grid item  xs={12} md={6} lg={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontWeight: 'bold' }}>OBŘAD</div>
                    <iframe style={{borderRadius: 5, width: '100%'}} src="https://frame.mapy.cz/s/jagosalaca" height="280" frameBorder="0"></iframe>
                </Grid>
                <Grid item  xs={12} md={6} lg={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontWeight: 'bold' }}>OSLAVA</div>
                    <iframe style={{borderRadius: 5, width: '100%'}} src="https://frame.mapy.cz/s/labazodaho" height="280" frameBorder="0"></iframe>
                </Grid>
                <Info title="PŘÍJEZD" imgIdx={0}>
                    <p>Budeme Vás očekávat od 12.30 na místě obřadu – u památného buku v zámeckém parku Lomnice u Tišnova. Doporučujeme ubytovat se předem; po obřadu a následném focení se přesuneme na místo oslav, kde bude následovat přípitek.</p>
                </Info>
                <Info title="UBYTOVÁNÍ" imgIdx={1}>
                    <>
                        <p>Pokud je to možné, poprosíme o zajištění přenocování u známých či rodin v okolí Tišnova.</p>
                        <p>Svatebčanům ze vzdálenějších končin můžeme zajistit ubytování v Tišnovské rychtě: kapacita cca 20 míst, cena 800 – 900,-/osoba/noc + možnost snídaně 200,-/noc (pro více informací mrkněte na jejich webové stránky).</p>
                        <p>Další možností je spaní ve stanu přímo na travnaté části Výletiště – samozřejmě bezplatně (kapacita 5 stanů).</p>
                        <p>V každém případě nám prosím dejte do 15. srpna vědět, jakou variantu noclehu jste si vybrali, ať můžeme vše včas zajistit.</p>
                        <p>Po celou dobu oslavy na Výletišti bude k dispozici odvoz na ubytování.</p>
                    </>
                </Info>
                <Info title="PARKOVÁNÍ" imgIdx={2}>
                    <>
                        <p>V Lomnici je možné zaparkovat na náměstí, případně v postranních ulicích (viz mapa).</p>
                        <p>V Lomničce se velké parkoviště nachází hned vedle areálu Výletiště (viz mapa).</p>
                    </>
                </Info>
                <Info title="CO NA SEBE" imgIdx={3}>
                    <>
                        <p>Obřad i následná oslava budou probíhat venku za každého počasí, proto prosíme o bedlivé sledování předpovědi a přizpůsobení vašeho outfitu aktuálnímu počasí.</p>
                        <p>Budeme rádi, pokud nás při obřadu podpoříte slavnostnějším oblečením jakýchkoliv barev (slovy nevěsty: čím víc, tím líp), dámy poprosíme bez podpatků. Na oslavu pak klidně zvolte pohodlnější oblečení a vzhledem k podzimnímu termínu doporučujeme nepodcenit teplé vrstvy. Večer bude možnost se přesunout do vytápěného sálu Výletiště.</p>
                    </>
                </Info>
                <Info title="SVATEBNÍ DARY" imgIdx={0}>
                    <p>Ač to bude znít jako fráze, tím největším darem pro nás bude skutečně vaše společnost a možnost sdílet s vámi tento výjimečný den. Pokud se rozhodnete nás něčím obdarovat, budeme rádi za osobní drobnost nebo příspěvek do našeho budoucího bydlení, které ke konci roku snad nabere konkrétnější představy.</p>
                </Info>
                <Info title="OBČERSTVENÍ" imgIdx={1}>
                    <p>Hlady rozhodně nebudete! Na Výletišti bude připravený teplý i studený raut. Co nejdřív nám prosím dejte vědět o případných potravinových alergiích.</p>
                </Info>
                <Info title="ODJEZD" imgIdx={2}>
                    <p>Oficiální odjezd v neděli plánovaný není. Pro vyzvednutí aut na Výletišti bude možné využít místní MHD (každé 2 hodiny), případně se prosím domluvte mezi sebou. Kdo se s námi bude chtít rozloučit osobně, může se za stavit na Výletiště, kde budeme od rána/dopoledne dávat areál do pořádku.</p>
                </Info>
                <Info title="KONTAKTY" imgIdx={3}>
                    <ul>
                        <li><a href="mailto:slavikova.katerina@gmail.com">slavikova.katerina@gmail.com</a>, <a href="tel:733571531">733&nbsp;571&nbsp;531</a></li>
                        <li><a href="mailto:totaales@seznam.cz">totaales@seznam.cz</a>, <a href="tel:720265089">720&nbsp;265&nbsp;089</a></li>
                    </ul>
                </Info>
            </Grid>
            <footer style={{ textAlign: 'center', fontStyle: 'italic', padding: '0.5rem'}}>
                <span>Copyright © 2023, Tomáš Chalupník,&nbsp;<a href="https://tchalupnik.cz" target="_blank" rel="noreferrer">tchalupnik.cz</a></span>
            </footer>
            <Fab color="primary" variant="extended" sx={{ position: 'fixed', right: '1rem', top: '1rem'}} onClick={() => navigate('/foto')}>
                <PhotoLibraryIcon sx={{ mr: 1 }} />
                Galerie
            </Fab>
        </div>
    )
}

export default Homepage
