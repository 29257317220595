import './App.css'
import {
    Backdrop,
    Box, CircularProgress, Typography,
} from '@mui/material'
import Masonry from '@mui/lab/Masonry'
import React, {useState} from 'react'
import galleryDescriptor from './gallery.descriptor.js'


/* eslint-disable react/prop-types */
const ImageDialog = ({open, idx, onClose}) => {
    return (
        <Backdrop open={open} onClick={onClose}>
            {
                open && (
                    <Box
                        component="img"
                        sx={{
                            maxHeight: '85vh',
                            display: 'block',
                            maxWidth: '85vw',
                            overflow: 'hidden',
                        }}
                        src={`/gallery/${galleryDescriptor[idx]}`}
                    />
                )
            }
        </Backdrop>
    )
}

const CircularProgressWithLabel = props => (
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
        <CircularProgress variant="determinate" {...props} />
        <Box
            sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                variant="caption"
                component="div"
                color="text.secondary"
            >{`${Math.round(props.value)}%`}</Typography>
        </Box>
    </Box>
)

const Gallery = () => {
    const [index, setIndex] = useState(-1)
    const [loaded, setLoaded] = useState(0)

    return (
        <div className="Gallery">
            {
                loaded < galleryDescriptor.length && (
                    <div style={{ width: '100%', marginTop: '5rem', marginBottom: '5rem', textAlign: 'center'}}>
                        <CircularProgressWithLabel value={100 * loaded / galleryDescriptor.length} />
                    </div>
                )
            }
            <Masonry columns={{ xs: 2, sm: 3 }} spacing={1} sx={{ pl: 1, pt: 1, display: loaded === galleryDescriptor.length ? 'flex' : 'none'}}>
                {galleryDescriptor.map((photo, idx) => (
                    <div key={photo} onClick={() => setIndex(idx)}>
                        <img
                            src={`/thumbnails/${photo}`}
                            srcSet={`/thumbnails/${photo}`}
                            style={{ cursor: 'pointer', width: '100%'}}
                            onLoad={() => setLoaded((l) => l + 1)}
                        />
                    </div>
                ))}
            </Masonry>
            <footer style={{ textAlign: 'center', fontStyle: 'italic', padding: '0.5rem'}}>
                <span>Copyright © 2023, Tomáš Chalupník,&nbsp;<a href="https://tchalupnik.cz" target="_blank" rel="noreferrer">tchalupnik.cz</a></span>
            </footer>
            <ImageDialog open={index > -1} idx={index} onClose={() => setIndex(-1)} setIndex={setIndex}/>
        </div>
    )
}

export default Gallery
