import React from 'react'
import {Paper} from '@mui/material'
import tape from './leaves/tape.png'

// eslint-disable-next-line react/prop-types
const Tape = ({ left, bottom, rotate, top, right}) => (
    <img
        src={tape}
        style={{
            width: '6rem',
            opacity: 0.8,
            position: 'absolute',
            left,
            bottom,
            top,
            right,
            transform: `rotate(${rotate})`
        }}
    />
)

const Announcement = () => {
    return (
        <Paper
            elevation={2}
            className="announcement">
            <p>
                Milí svatební hosté,<br /><br />
                asi nejde slovy vyjádřit, jak moc jsme štastní, že jste byli s námi. Děkujeme za úsměvy, krásná slova gratulací, svatební dary a především za společné chvíle. Jsme unavení, ale šťastní a dojatí. V následujících dnech sem vyvěsíme ochutnávku svatebních fotek.<br /><br />
                Děkujeme!<br /><br />
                <b>Totovi</b>
            </p>
            <Tape left="-2.4rem" bottom={0} rotate="45deg" />
            <Tape left="-2.4rem" top={0} rotate="135deg" />
            <Tape right="-2.4rem" top={0} rotate="225deg" />
            <Tape right="-2.4rem" bottom={0} rotate="315deg" />
        </Paper>
    )
}

export default Announcement
