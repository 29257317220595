import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Homepage from './Homepage'
import reportWebVitals from './reportWebVitals'
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import Gallery from './Gallery'
import {createTheme, ThemeProvider} from '@mui/material'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyC6T-pvcs8nRSeO4gSo1YFruMsK0Q7F_rs',
    authDomain: 'katka-a-alda.firebaseapp.com',
    projectId: 'katka-a-alda',
    storageBucket: 'katka-a-alda.appspot.com',
    messagingSenderId: '736902458613',
    appId: '1:736902458613:web:3965bdacac0845255ce9bc',
    measurementId: 'G-M0YBENVYC4'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app)

const router = createBrowserRouter([
    {
        path: '/',
        element: <Homepage />,
        errorElement: <Homepage />
    },
    {
        path: '/foto',
        element: <Gallery />,
    },
])

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(205, 208, 57)',
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
