import React from 'react'
import {Grid, Paper} from '@mui/material'

import jetel from './leaves/jetel.jpeg'
import jitrocel from './leaves/jitrocel kopinaty.jpeg'
import jitrocel2 from './leaves/jitrocel vetsi.jpeg'
import mrkev from './leaves/mrkev.jpeg'

const leaves = [jetel, jitrocel, jitrocel2, mrkev]

// eslint-disable-next-line react/prop-types
const Info = ({title, children, imgIdx}) => {
    return (
        <Grid item  xs={12} md={6} lg={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Paper sx={{
                overflow: 'hidden',
                color: 'inherit',
                height: '100%',
                position: 'relative',
                padding: '2rem',
                // backgroundImage: `url(${jetel})`,
                // backgroundRepeat: 'no-repeat',
                // backgroundPosition: 'center',

            }} elevation={2} className="info-item">

                <img src={leaves[imgIdx]} style={{
                    opacity: 0.2,
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: '70%',
                    height: 'auto',
                    margin: 'auto'
                }}></img>
                <h4>{title}</h4>
                <div>{children}</div>
            </Paper>
        </Grid>
    )
}

export default Info
